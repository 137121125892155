@import "styles/uxd_theme";

.CollateralCompositionTable {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th,
  td {
    padding: rem-calc($spacing-small) 0;
    line-height: 1.5;
  }

  tr:last-child {
    td {
      padding-bottom: 0;
    }
  }

  th {
    color: $grey;
  }

  th,
  tr td {
    text-align: center;
  }

  th:first-child,
  tr td:first-child {
    text-align: left;
  }

  th:last-child,
  tr td:last-child {
    text-align: right;
  }

  td a {
    color: $white;
    text-transform: initial;
  }
}

.CollateralCompositionTotalBalance {
  display: flex;
}

.CollateralCompositionTotalBalance-title {
  color: $grey;
  padding-right: rem-calc($spacing-small);
}

.CollateralCompositionTable-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.CollateralCompositionTable-col--asset {
  @media (max-width: $viewport-w-large) {
    display: none;
  }
}

.CollateralCompositionTable-col--value-full {
  display: none;

  @media (min-width: $viewport-w-large) {
    display: initial;
  }
}

.CollateralCompositionTable-col--value-compact {
  @media (min-width: $viewport-w-large) {
    display: none;
  }
}
