@import "styles/uxd_theme";

.StakingCampaign {
}

.StakingCampaign-info {
  display: flex;
  flex-direction: column;
  margin-bottom: rem-calc($spacing-large);
}

.StakingCampaign-info strong {
  margin-bottom: rem-calc($spacing-small);
  color: $grey;
}

.StakingCampaign-info em {
  font-style: normal;
}

.StakingCampaign-token {
  display: flex;
  align-items: center;
  margin-bottom: rem-calc($spacing-xlarge);
}

.StakingCampaign-token-icon {
  flex-shrink: 0;
  margin-right: rem-calc($spacing);
  height: rem-calc($spacing-xxlarge);
  width: rem-calc($spacing-xxlarge);
  @media (min-width: $viewport-w-medium) {
    width: rem-calc($spacing-kingsize);
    height: rem-calc($spacing-kingsize);
  }
}

.StakingCampaign-token-label {
  display: flex;
  flex-direction: column;
  font-size: rem-calc($spacing-large);
}

.StakingCampaign-token-label em {
  font-size: rem-calc($spacing);
}
