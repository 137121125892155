@import "styles/uxd_theme";

.StakingForm {
  display: flex;
  border: 1px solid $border-grey;

  flex-direction: column;
  @media (min-width: $viewport-w-large) {
    flex-direction: row;
  }
}

.StakingForm section {
  padding: rem-calc($spacing-large) rem-calc($spacing-xlarge);
  width: 100%;

  @media (min-width: $viewport-w-large) {
    max-width: rem-calc(560px);
  }

  & + section {
    max-width: 100%;
    border-top: 1px solid $border-grey;

    @media (min-width: $viewport-w-large) {
      border-top: 0;
      border-left: 1px solid $border-grey;
    }
  }

  h4 {
    margin: 0 0 rem-calc($spacing-xlarge);
    color: $white;
    em {
      font-style: normal;
      color: $grey;
    }
  }
}

.StakingForm-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: rem-calc($spacing-smallish);

  @media (min-width: $viewport-w-medium) {
    flex-direction: row;
  }
}

.StakingForm-info strong {
  margin-bottom: rem-calc($spacing-small);
  @media (min-width: $viewport-w-medium) {
    margin-bottom: 0;
  }

  color: $grey;
}

.StakingForm-info em {
  font-style: normal;
}

.StakingForm-info--apr {
  color: $primary-green;
}

.StakingForm-info--fallback {
  color: $white;
}

.StakingForm-fieldset {
  margin-bottom: rem-calc($spacing);
}

.StakingForm-duration-apr-label em {
  font-style: normal;
}

.StakingForm-duration-apr-label strong {
  color: $primary-green;
}

.StakingForm-input-label-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem-calc($spacing-small);
}

.StakingForm-disclaimer {
  color: $primary-yellow;
}

.StakingForm-separator {
  margin: rem-calc($spacing-large) 0;
  border: 0;
  border-bottom: 1px solid $border-grey;
}

.StakingForm-submit {
  margin-top: rem-calc($spacing-xlarge);
}
