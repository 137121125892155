@import "styles/uxd_theme";

.UXPCallToAction {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  text-decoration: none;

  font-weight: $font-weight-bold-inconsolata;
  font-size: 1rem;

  padding: rem-calc($spacing-small $spacing-smallish);

  border: 1px solid $border-grey;

  background-color: $black;

  &,
  &:visited {
    color: $white;
  }

  &:hover {
    background: $button-hover-grey;
    color: $white;
  }

  &:active {
    border: 1px solid $white;
  }

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: $button-outline-offset;
  }
}
