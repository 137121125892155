@import "styles/uxd_theme";

@mixin inconsolata-font-base {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.Dropdown-standalone {
  position: relative;
  top: 0.25rem;
  z-index: 2;
}

.Dropdown-anchor {
  @include reset-button;
  @include inconsolata-font-base;

  position: relative;
  padding: 0.5rem;
  padding-right: calc(0.5rem + 0.31rem);

  font-size: inherit;
  color: white;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: $button-outline-offset;
  }

  &::after {
    position: absolute;
    top: 0.9rem;
    right: -0.1em;

    content: "";
    width: 0;
    height: 0;

    border-left: 0.31rem solid transparent;
    border-right: 0.31rem solid transparent;

    border-top: 0.31rem solid $white;
    border-bottom: 0;
  }

  &.Dropdown-anchor--open::after {
    border-left: 0.31rem solid transparent;
    border-right: 0.31rem solid transparent;

    border-bottom: 0.31rem solid $white;
    border-top: 0;
  }
}

.Dropdown-content {
  position: absolute;
  top: 2.5rem;
  left: 0;

  display: flex;
  flex-direction: column;
  padding: 0.25rem;

  background-color: $black;
  border: 1px solid $border-grey;
  border-radius: 3px;
}

.Dropdown-option {
  @include reset-button;
  @include inconsolata-font-base;

  font-size: inherit;
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: white;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    background-color: #191919;
  }

  &:hover,
  &:active {
    background-color: #191919;
  }

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: -$button-outline-offset;
  }
}

.Dropdown-option--with-icon {
  display: inline-flex;
  align-items: center;

  & > svg {
    width: auto;
    padding-right: 1rem;
  }
}
