@import "styles/uxd_theme";

.InsuranceFundPositions-title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.InsuranceFundTotalBalance {
  display: flex;
}

.InsuranceFundTotalBalance-title {
  color: $grey;
  padding-right: rem-calc($spacing-small);
}

.InsuranceFundPositions {
  overflow-x: auto;
  overflow-y: hidden;
}

.InsuranceFundPositions table,
.InsuranceFundBalance table {
  width: 100%;
  border-collapse: collapse;

  thead tr th {
    padding-top: 0;
  }

  th,
  td {
    padding: rem-calc($spacing-small) 0;
    line-height: 1.5;
  }

  th:not(:last-child),
  td:not(:last-child) {
    padding-right: rem-calc($spacing-small);
  }

  tr:last-child {
    td {
      padding-bottom: 0;
    }
  }

  th {
    color: $grey;
  }

  td a {
    color: $white;
    text-transform: initial;
  }
}

.InsuranceFundBalance table {
  margin-bottom: rem-calc($spacing-intermediate);

  th,
  td {
    padding-top: 0;
  }
}

.InsuranceFundPositions-col--address {
  @media (max-width: $viewport-w-smallish) {
    display: none;
  }
}

.InsuranceFundPositions-col--blockchain,
.InsuranceFundPositions-col--platform,
.InsuranceFundPositions-col--position,
.InsuranceFundPositions-col--value {
  text-align: left;
}

.InsuranceFundPositions-col--fund-percentage {
  text-align: right;
}

.InsuranceFundPositions-col--blockchain {
  display: none;
}

// Need this so the columns are aligned for all arrays
@media (min-width: $viewport-w-smallish) {
  .InsuranceFundPositions-col--platform {
    width: 20%;
  }

  // The position column will be the one that grows

  .InsuranceFundPositions-col--blockchain {
    width: 15%;
    display: flex;
  }

  .InsuranceFundPositions-col--value {
    width: 30%;
  }

  .InsuranceFundPositions-col--fund-percentage {
    width: 10%;
  }
}

.InsuranceFundPositions-col--fund-percentage {
  white-space: nowrap;
}

.InsuranceFundPositions-col--value-full {
  display: none;

  @media (min-width: $viewport-w-large) {
    display: initial;
  }
}

.InsuranceFundPositions-col--value-compact {
  @media (min-width: $viewport-w-large) {
    display: none;
  }
}
