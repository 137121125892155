@import "styles/uxd_theme";

.ExternalLink {
  padding-top: $spacing-small;
  padding-bottom: $spacing-small;

  text-decoration: underline;
  color: $white !important;
  width: fit-content;
  text-transform: uppercase !important;
}
