@import "styles/uxd_theme";

.Settings {
  width: 100%;
  // FIXME: margin-bottom should be set by parent component
  margin-top: 1rem;
  text-transform: uppercase;
}

.Settings-row {
  display: flex;
  justify-content: space-between;
}

.Settings-row:not(:last-child) {
  margin-bottom: 0.5rem;
}

.Settings-slippage-button {
  text-decoration: none;

  &:not([data-disabled]) {
    &,
    &:visited {
      color: $primary-green;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &[data-disabled] {
    pointer-events: none;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}
