@import "styles/uxd_theme";

.TermsOfService {
  display: flex;
  align-items: start;
  line-height: 1.5em;

  input {
    flex-grow: 0;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-right: rem-calc($spacing-smallish);
  }
}
