@import "styles/uxd_theme";

.Gauge {
  width: 100%;

  @media (max-width: $viewport-w-medium) {
    display: flex;
    flex-direction: column;
  }

  padding: rem-calc($spacing-xxsmall) 0;
  text-align: center;

  border: 1px solid $border-grey;
}

.Gauge-title {
  margin-left: rem-calc($spacing-smallish);
}
