$rem-base: 16px;

$white: #ffff;
$black: #000;
$primary-green: #24ff00;
$secondary-green: #138500;
$tooltip-green: #002622;
$primary-yellow: #ebff00;
$secondary-yellow: #1a1c00;
$secondary-yellow-darker: #131400;
$primary-purple: #ff00f5;
$secondary-purple: #260025;
$secondary-purple-darker: #1a0019;
$grey: #868686;
$button-hover-grey: #1a1a1a;
$border-grey: #333333;
$link-blue: #00ffe0;

// Extraneous colors
$blue: #00ffe0;
$red: #ff0000;
$dark-white: #bfbfbf;

// Collaterals
$color-SOL: #bd00ff;
$color-BTC: #ffa012;
$color-ETH: #00b2ea;

$font-family-inconsolata: "Inconsolata", sans-serif;
$font-weight-base-inconsolata: 400;
$font-weight-bold-inconsolata: 700;
$letter-spacing-inconsolata: 0.1em;
$text-transform-inconsolata: uppercase;

// Use with rem-calc($value1 ...) if value should scale with base font-size.
$spacing-xxsmall: 3px;
$spacing-xsmall: 6px;
$spacing-small: 8px;
$spacing-smallish: 12px;
$spacing-smaller: 14px;
$spacing: 16px; // rem-base
$spacing-intermediate: 20px;
$spacing-large: 24px;
$spacing-xlarge: 32px;
$spacing-xxlarge: 40px;
$spacing-xxlargeish: 40px;
$spacing-xxxlarge: 56px;
$spacing-kingsize: 72px;

// Minimum supported resolution should be
// Iphone 5: 320 x 568

$viewport-w-xxsmall: 340px;
$viewport-w-xsmall: 375px;
$viewport-w-small: 400px;
$viewport-w-smallish: 550px;
$viewport-w-medium: 768px;
$viewport-w-large: 1024px;
$viewport-w-navigation: 1160px;
$viewport-w-xlarge: 1280px;
$viewport-w-xxlarge: 1440px;

$button-outline-offset: 3px;
