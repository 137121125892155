@import "styles/uxd_theme";

.Tokens {
  position: relative;

  display: flex;
  flex-direction: column;

  @media (min-width: $viewport-w-medium) {
    flex-direction: row;
  }

  > * + * {
    margin-top: rem-calc($spacing-small);
    @media (min-width: $viewport-w-medium) {
      margin-top: 0;
      margin-left: rem-calc($spacing-large);
    }
  }
}
