@import "styles/uxd_theme";

@mixin incosolata-font-base {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.Slippage-title {
  // cumulated width of the "close modal" button
  padding-right: 3.332rem;
}

.Slippage-warning {
  display: block;
  margin-top: 0.5rem;
  color: $primary-yellow;
}

.Slippage-input-wrapper {
  display: flex;
  flex-direction: column;
}

.Slippage-input-wrapper label {
  margin-bottom: 0.5rem;
}

.Slippage-input-container::after {
  content: "%";
  position: relative;
  left: -1.5rem;
  font-size: 1.5rem;
  color: #24ff00;
}

.Slippage-input-wrapper input[type="text"] {
  // enough space for the input to contain "99.0"
  width: 7.5rem;
  padding: 0.5rem 1rem;
  padding-right: 2.5rem;
  background-color: transparent;
  border: 1px solid $border-grey;
  @include incosolata-font-base;
  font-size: 1.5rem;
  color: white;

  &::placeholder {
    color: $grey;
  }

  &:focus {
    outline: none;
    border-color: #24ff00;
  }
}
