@import "styles/uxd_theme";

.Swap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-width: rem-calc(330px);

  @media (min-width: $viewport-w-medium) {
    min-width: rem-calc(450px);
  }

  width: 100%;
  max-width: 33%;

  margin-bottom: rem-calc($spacing-xxlarge);
}

.Redemption-Banner {
  background-color: #e37718;
  color: black;
  width: 100%;
  text-align: center;
  padding: 9px;
  height: 33px;

  a,
  a:visited {
    color: black;
    text-decoration: underline;
  }
}

.Swap-inner {
  width: 100%;
  border: 1px solid $border-grey;
  border-bottom: 0;
}

.Swap-tabs {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $border-grey;
}

.Swap-tab {
  width: 100%;
  padding: rem-calc($spacing-large) 0;
  font-size: rem-calc($spacing-large);
  border: 0;

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: -$button-outline-offset;
  }
}

.Swap-tab-mint {
  color: $primary-yellow;

  &:hover {
    background-color: $secondary-yellow-darker;
  }

  &:active,
  &.Swap-tab-active {
    background-color: $secondary-yellow;
  }
}

.Swap-tab-redeem {
  color: $primary-purple;

  &:hover {
    background-color: $secondary-purple-darker;
  }

  &:active,
  &.Swap-tab-active {
    background-color: $secondary-purple;
  }
}

.Swap-content {
  padding: rem-calc($spacing-xlarge) rem-calc($spacing-large);
}
