@import "styles/uxd_theme";

$phantom-color: #533dbf;
$solflare-color: #fc7227;
$slope-color: #6d65fa;
$exodus-color: #8044ff;
$backpack-color: #e62134;

.WalletList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.WalletList-button {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: rem-calc($spacing-smallish);
  }
  font-size: rem-calc($spacing-large);
}

.WalletList-button--phantom {
  color: $phantom-color;
  &:active {
    border-color: $phantom-color;
  }
}

.WalletList-button--solflare {
  color: $solflare-color;
  &:active {
    border-color: $solflare-color;
  }
}

.WalletList-button--slope {
  color: $slope-color;
  &:active {
    border-color: $slope-color;
  }
}

.WalletList-button--exodus {
  color: $exodus-color;
  &:active {
    border-color: $exodus-color;
  }
}

.WalletList-button--backpack {
  color: $backpack-color;
  &:active {
    border-color: $backpack-color;
  }
}

.WalletList-button-icon {
  width: rem-calc($spacing-large);
  height: rem-calc($spacing-large);
  margin-right: rem-calc($spacing);
}
