@import "styles/uxd_theme";

.transactionLink {
  text-decoration: underline;
  color: $white;
}

.transactionLink:hover {
  color: $primary-green;
}
