@import "styles/uxd_theme";

.App {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  max-width: $viewport-w-xxlarge;

  margin: 0 auto;
  padding: rem-calc($spacing-large);

  @media (min-width: $viewport-w-medium) {
    padding: rem-calc($spacing-large $spacing-xxlarge);
  }
}

.App-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  transform: translateZ(0);
}

.App-overlay {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  width: 100%;
}

.App-message {
  text-align: center;
}

.Redemption-Banner {
  background-color: #e37718;
  color: black;
  width: 50%;
  text-align: center;
  padding: 9px;
  height: 33px;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 120px;
  position: fixed;

  a,
  a:visited {
    color: black;
    text-decoration: underline;
  }
}
