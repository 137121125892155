@import "styles/uxd_theme";

.Navigation-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  text-decoration: none;

  font-size: 1rem;

  padding: rem-calc($spacing-small);

  border: 0;

  background-color: $black;

  &,
  &:visited {
    color: $white;
  }

  &:hover {
    background: $button-hover-grey;
    color: $white;
  }

  &:active,
  &.Navigation-link--active {
    background: $white;
    color: $black;
  }

  &.Navigation-link--active {
    pointer-events: none;
  }

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: $button-outline-offset;
  }
}
