@import "styles/uxd_theme";

.InputMaxButton {
  font-size: 1em;
  color: $grey;
  &:not(.InputMaxButton--disabled):hover {
    color: $white;
    cursor: pointer;
  }
}
