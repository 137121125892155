@import "styles/uxd_theme";

.StakingPositions {
  margin-bottom: rem-calc($spacing-xlarge);
}

.StakingPositions h3 {
  margin: 0 0 rem-calc($spacing);
  font-size: rem-calc($spacing);
}

.StakingPositions-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $viewport-w-smallish) {
    flex-direction: row;
    justify-content: space-between;
  }

  margin-bottom: rem-calc($spacing);

  button {
    margin-top: rem-calc($spacing-large);

    @media (min-width: $viewport-w-smallish) {
      margin-top: 0;
    }
  }

  h3 {
    margin: 0;
  }
}

.StakingPositions-content,
.StakingPositions-footer {
  padding: rem-calc($spacing-large) rem-calc($spacing-xlarge);
  border: 1px solid $border-grey;
}

.StakingPositions-footer {
  padding: rem-calc($spacing) rem-calc($spacing-large);
  font-size: rem-calc($spacing-smaller);
  color: $grey;
  border-top: 0;
}

.StakingPositions p {
  margin: 0;
}

.StakingPositions table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  th,
  td {
    padding-bottom: rem-calc($spacing-small);
    line-height: 1.5;
  }

  tr:last-child {
    td {
      padding-bottom: 0;
    }
  }

  th {
    color: $grey;
  }

  th:first-child,
  tr td:first-child {
    text-align: left;
  }

  th:last-child,
  tr td:last-child {
    text-align: right;
  }

  td a {
    color: $white;
    text-transform: initial;
  }
}

.StakingPositions-claim-button {
  color: $black;
  background-color: $primary-green;
  border: 0;

  &:hover {
    background-color: #1dcc00;
  }

  &:active {
    background-color: #18a800;
  }

  &:focus-visible {
    background-color: $primary-green;
    outline: 1px solid $secondary-green;
    outline-offset: $button-outline-offset;
  }

  &[disabled] {
    background-color: $grey;
    opacity: 1;
    cursor: not-allowed;
    pointer-events: initial;
  }
}

.StakingPositions p {
  text-align: center;
}
