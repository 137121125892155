@import "styles/uxd_theme";

.Options {
  margin-bottom: rem-calc($spacing-large);
}

.Options-container {
  display: flex;
  flex-wrap: wrap;
  gap: rem-calc($spacing-small);
}

.Options-legend {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-bottom: rem-calc($spacing-small);
}

.Options-legend:before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 100%;
  border-top: 1px dashed $border-grey;
}

.Options-legend > * {
  z-index: 1;
  background-color: $black;
}

.Options-legend legend {
  padding-right: rem-calc($spacing-small);
}

.Options-legend legend + span {
  padding-left: rem-calc($spacing-small);
  color: $grey;
}

.Options-option {
  display: flex;
}

.Options-option input[type="radio"] {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.Options-option label {
  margin-right: -1px;
  font-size: 1rem;
  padding: rem-calc($spacing) rem-calc($spacing-intermediate);

  @media (min-width: $viewport-w-medium) {
    padding: rem-calc($spacing) rem-calc($spacing-large);
  }

  background-color: $black;
  text-align: center;
  border: 1px solid $border-grey;
}

.Options-option label:hover,
// FIXME: should have its own :focus style.
.Options-option input[type="radio"]:focus + label {
  cursor: pointer;
  border-color: #24ff00;
}

.Options-option input[type="radio"]:checked + label {
  background-color: #24ff00;
  color: $black;
}
