@import "styles/uxd_theme";

$logo-width: rem-calc(144px);
$logo-height: rem-calc($spacing-xxlarge);

.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $viewport-w-navigation) {
    flex-direction: row;
    justify-content: space-between;
  }

  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: $logo-height;
  margin-bottom: rem-calc($spacing-kingsize);
}

.Header-widget-group {
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    margin-bottom: rem-calc($spacing);

    @media (min-width: $viewport-w-navigation) {
      margin-bottom: 0;
    }
  }
}

.Header-widget:not(:last-child) {
  margin-right: rem-calc($spacing);
}

.Header-logo {
  width: $logo-width;
  height: $logo-height;
  flex-shrink: 0;
  background-image: url("../../assets/images/uxd-logo-horizontal-fill-white.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Header-widget-gauge {
  width: rem-calc(256px);

  @media (max-width: $viewport-w-medium) {
    width: rem-calc(144px);
  }
}
