@mixin reset-button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
  padding: 0;
  border: 0;
  font-size: 1rem;
  line-height: 1rem;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}
