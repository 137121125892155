@import "styles/uxd_theme";

.Notification {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $black;
}

.Notification-header {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid $border-grey;
  padding-left: rem-calc($spacing);
}

.Notification-header h5 {
  margin: 0;
  flex-grow: 1;
  font-size: rem-calc($spacing);
  font-weight: normal;
}

.Notification-title-icon {
  padding-right: rem-calc($spacing);
}

.Notification-details {
  max-width: 100%;
  overflow: auto;
}

.Notification-header button {
  padding: 0;
  border: 0;
  border-left: 1px solid $border-grey;

  &:hover,
  // FIXME: focus & active should have their own styling
  &:focus-visible,
  &:active {
    background-color: white;
  }

  svg {
    // FIXME: should be 1rem but SVG itself is not properly sized
    height: 2rem;
    width: auto;
    // FIXME: should be able to either set `color` on container, or `fill` on svg.
    path {
      fill: $red;
    }
  }
}

.Notification-title-icon--success {
  color: $primary-green;
}

.Notification-title-icon--warning {
  color: $primary-yellow;
}

.Notification-title-icon--error {
  color: $red;
}

.Notification-inner p {
  margin: 0;
  padding: rem-calc($spacing);
}

p.Notification-details {
  padding-top: rem-calc($spacing-small);
  padding-bottom: rem-calc($spacing-small);
  white-space: pre-line;
  font-size: $spacing-smallish;
  border-top: 1px solid $border-grey;

  em {
    text-transform: none;
  }
}
