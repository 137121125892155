@import "styles/uxd_theme";

.TokenInfo {
  flex-grow: 0;
  display: flex;
  align-items: center;
  padding: rem-calc($spacing);
  z-index: 2;
  width: 100%;
  @media (min-width: $viewport-w-large) {
    max-width: rem-calc(315px);
  }
  background-color: $black;
  border: 1px solid $border-grey;
  text-decoration: none;

  background-position: top rem-calc($spacing-small) right
    rem-calc($spacing-small);
  background-repeat: no-repeat;
  background-size: rem-calc($spacing-smaller);
  background-image: url("../../../assets/icons/External.svg");

  &:hover {
    border-color: $white;
  }
}

.TokenInfo-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media (min-width: $viewport-w-large) {
    justify-content: space-between;
    padding: rem-calc($spacing-smallish) 0;
  }
}

.TokenInfo-token-label {
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  color: $grey;
}

.TokenInfo-token-price {
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  color: $white;
}

.TokenInfo-icon {
  flex-shrink: 0;
  margin-right: rem-calc($spacing);
  height: rem-calc($spacing-xxlarge);
  width: rem-calc($spacing-xxlarge);
  @media (min-width: $viewport-w-medium) {
    width: rem-calc($spacing-kingsize);
    height: rem-calc($spacing-kingsize);
  }
}
