@import "styles/uxd_theme";

.Network {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;

  &::before {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin: 0 0.5rem;
    background: $primary-green;
    content: "";
  }
}

.Network-other {
  margin-left: $spacing;
}

.Network-dropdown-anchor {
  display: flex;
  align-items: center;
}
