@import "styles/uxd_theme";

.WalletButton-connect {
  color: $black;
  background-color: $primary-green;
  border: 0;

  &:hover {
    background-color: #1dcc00;
  }

  &:active {
    background-color: #18a800;
  }

  &:focus-visible {
    background-color: $primary-green;
    outline: 1px solid $secondary-green;
    outline-offset: $button-outline-offset;
  }
}

.WalletButton-disconnect {
  border-color: $white;

  &:hover,
  &:active,
  &:focus-visible {
    border-color: $border-grey;
    background-color: $button-hover-grey;
  }
}
