@import "styles/uxd_theme";

@keyframes open {
  0% {
    right: -100vw;
  }

  75% {
    right: 5%;
  }

  100% {
    right: 0;
  }
}

@keyframes close {
  100% {
    right: -100vw;
  }

  25% {
    right: 5%;
  }

  0% {
    right: 0;
  }
}

.NotificationContainer {
  position: fixed;
  z-index: 2;

  top: 3rem;
  right: 0;

  min-width: 17em;

  @media (min-width: $viewport-w-medium) {
    min-width: 28rem;
  }

  animation: open 0.5s ease-out both;

  max-width: 100%;

  border: 1px solid $border-grey;
}

.NotificationContainer-hovering {
  border-color: $white;
}

.NotificationContainer-close {
  animation: close 0.5s ease-out forwards;
}
