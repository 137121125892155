@import "styles/uxd_theme";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  height: -webkit-fill-available;
  font-size: 16px;
  touch-action: manipulation;
  font-feature-settings: "case" 1, "rlig" 1, "calt" 0;
  background: $black;
}

html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background: transparent;

  color: $white;
  font-size: 1rem;
  font-weight: $font-weight-base-inconsolata;
  font-family: $font-family-inconsolata;
  letter-spacing: $letter-spacing-inconsolata;
  text-transform: $text-transform-inconsolata;
}

p {
  margin: 0;
  margin-bottom: 1em;
}

a,
a:visited {
  color: $link-blue;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:active {
  color: $white;
  text-decoration: none;
}

a:focus-visible {
  outline: 1px solid $secondary-green;
  outline-offset: $button-outline-offset;
}

.page-error-container {
  padding: rem-calc($spacing);
  line-height: 1.2em;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

main {
  margin-top: auto;
  margin-bottom: auto;
}
