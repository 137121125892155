@import "styles/uxd_theme";

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
}

.Footer a,
.Footer a:visited {
  text-decoration: none;
  color: white;
}

.Footer a {
  transform: none;
  transition-duration: 0.5s;
  transition: color 0.5s, transform 0.5s;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible,
  &:active {
    transform: scale(1.25);

    .Footer-icon-twitter {
      color: $blue;
    }

    .Footer-icon-github {
      color: $primary-green;
    }

    .Footer-icon-medium {
      color: $primary-purple;
    }

    .Footer-icon-gitbook {
      color: $primary-yellow;
    }

    .Footer-icon-discord {
      color: $red;
    }
  }
}

.Footer-vertical-separator {
  border-left: 0.125rem solid $border-grey;
  margin-right: 1.5rem;
  height: 1em;
  color: $white;
  width: 0;
}

.Footer-text-link {
  padding-bottom: 0.125rem;
  border-bottom: 1px solid $white;

  &:hover,
  &:active,
  &:focus-visible {
    border-bottom: 1px solid $primary-green;
  }
}
