@import "styles/uxd_theme";

.InputAmount {
  width: 100%;
  position: relative;
  margin-bottom: rem-calc($spacing-xlarge);
  background: $black;

  &:hover input:not(:disabled) {
    border-color: $white;
  }

  &:hover input:disabled {
    cursor: not-allowed;
  }

  input {
    // stacking context so the input receives :hover while hovering label or icon
    z-index: 1;
    position: relative;
    background-color: transparent;

    width: 100%;
    padding: rem-calc($spacing-small) rem-calc($spacing);
    padding-left: 3rem;
    padding-right: 4rem;

    font-size: rem-calc($spacing-large);
    font-weight: $font-weight-base-inconsolata;
    font-family: $font-family-inconsolata;
    letter-spacing: $letter-spacing-inconsolata;
    text-transform: $text-transform-inconsolata;

    color: white;
    border: 1px solid $border-grey;

    &::placeholder {
      color: $grey;
    }

    &:not(:disabled):focus {
      outline: 1px solid $secondary-green;
      outline-offset: 3px;
    }
  }
}

.InputAmount-asset,
.InputAmount-asset-icon {
  position: absolute;
  bottom: 0.55rem;
}

.InputAmount-asset {
  right: 1rem;
  font-size: 1.5rem;
}

.InputAmount-asset-icon {
  height: 1.5rem;
  width: 1.5rem;
  left: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.InputAmount-asset-icon--uxd {
  background-image: url("../../../assets/icons/UXD-icon.svg");
}
.InputAmount-asset-icon--sol {
  background-image: url("../../../assets/icons/SOL-icon.svg");
}
.InputAmount-asset-icon--btc {
  background-image: url("../../../assets/icons/BTC-icon.svg");
}
.InputAmount-asset-icon--eth {
  background-image: url("../../../assets/images/soETH-icon.png");
}
.InputAmount-asset-icon--weth {
  background-image: url("../../../assets/images/WETH-icon.png");
}
.InputAmount-asset-icon--uxp {
  background-image: url("../../../assets/icons/UXP-token-icon.svg");
}
.InputAmount-asset-icon--usdc,
.InputAmount-asset-icon--usdc_mercurial,
.InputAmount-asset-icon--usdc_credix,
.InputAmount-asset-icon--usdc_arbitrum {
  background-image: url("../../../assets/icons/USDC-icon.svg");
}
