@import "styles/uxd_theme";

.CollateralForm label {
  display: inline-block;
  margin-bottom: rem-calc($spacing-small);
}

.CollateralForm label em {
  font-style: normal;
  color: $grey;
}

.CollateralForm-label-wrapper {
  display: flex;
  justify-content: space-between;
}

.CollateralForm-addon {
  display: flex;
  justify-content: center;
  margin-bottom: rem-calc($spacing-large);
}
