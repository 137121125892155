@import "styles/uxd_theme";

.Section {
  padding: 0;
  margin-bottom: rem-calc($spacing);
}

.Section-title {
  margin: 0;
  margin-bottom: rem-calc($spacing);
  font-size: 1rem;
}

.Section-content {
  display: flex;
  flex-direction: column;

  @media (min-width: $viewport-w-large) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.Section-content > * {
  width: 100%;
}

.Section-content > * + * {
  margin-top: rem-calc($spacing);
  @media (min-width: $viewport-w-large) {
    margin-top: 0;
  }
}

.Section-title + .Section-content {
  justify-content: initial;
  flex-direction: column;
  border: 1px solid $border-grey;
  padding: rem-calc($spacing);
}

.Section-content > p {
  margin: 0;
  text-align: center;
}
