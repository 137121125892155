@import "styles/uxd_theme";

.SwapArbitrum {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-width: rem-calc(330px);
  @media (min-width: $viewport-w-medium) {
    min-width: rem-calc(450px);
  }
  width: 100%;
  max-width: 33%;

  margin-bottom: rem-calc($spacing-xxlarge);
}

.SwapArbitrum-inner {
  width: 100%;
  border: 1px solid $border-grey;
  border-bottom: 0;
}

.SwapArbitrum-tabs {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $border-grey;
}

.SwapArbitrum-tab {
  width: 100%;
  padding: rem-calc($spacing-large) 0;
  font-size: rem-calc($spacing-large);
  border: 0;

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: -$button-outline-offset;
  }
}

.SwapArbitrum-tab-mint {
  color: $primary-yellow;

  &:hover {
    background-color: $secondary-yellow-darker;
  }

  &:active,
  &.SwapArbitrum-tab-active {
    background-color: $secondary-yellow;
  }
}

.SwapArbitrum-tab-redeem {
  color: $primary-purple;

  &:hover {
    background-color: $secondary-purple-darker;
  }

  &:active,
  &.SwapArbitrum-tab-active {
    background-color: $secondary-purple;
  }
}

.SwapArbitrum-content {
  padding: rem-calc($spacing-xlarge) rem-calc($spacing-large);
}

.SwapArbitrum-warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: rem-calc($spacing-intermediate);

  span {
    text-align: center;
  }

  svg {
    width: $spacing-intermediate;
    margin-right: rem-calc($spacing-small);
  }

  Button {
    margin-left: rem-calc($spacing-small);
  }
}
