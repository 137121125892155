@import "styles/uxd_theme";

@mixin inconsolata-font-base {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin base-button {
  @include reset-button;
  @include inconsolata-font-base;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: rem-calc($spacing-small $spacing-smallish);

  font-weight: $font-weight-base-inconsolata;
  font-size: 1rem;

  border: 1px solid $border-grey;

  cursor: pointer;
  color: white;

  &:hover {
    background-color: $button-hover-grey;
  }

  &:active {
    border-color: $white;
  }

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: $button-outline-offset;
  }
}

.Button {
  @include base-button;
}

.Button[disabled] {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  background: transparent;
}
