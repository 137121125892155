@import "styles/uxd_theme";

.ActionButton {
  width: 100%;
  font-size: 1.5rem;
  padding: rem-calc($spacing-large) 0;
  &:hover {
    border-color: $border-grey;
    border-bottom: 1px solid $white;
  }

  &:focus-visible {
    outline: 1px solid $secondary-green;
    color: $primary-green;
    background-color: $black;
  }
}

.ActionButton-green:not(:disabled) {
  color: $primary-green;
  &:hover {
    background-color: #011401;
    border-bottom: solid $primary-green 1px;
  }

  &:active {
    color: $black;
    background-color: $primary-green;
    border: 1px solid $primary-green;
  }
}
