@import "styles/uxd_theme";

@mixin inconsolata-font-base {
  font-family: Inconsolata;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: $black;
  opacity: 0.8;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.Modal-inner {
  // Inner modal stacking context, above the overlay.
  position: relative;
  z-index: 2;

  display: flex;
  flex-direction: column;

  min-width: rem-calc(330px);
  @media (min-width: $viewport-w-medium) {
    min-width: rem-calc(450px);
  }
  width: 100%;
  max-width: 33%;

  background-color: $black;
  border: 1px solid $border-grey;

  @include inconsolata-font-base;
  color: #ffffff;

  &.Modal-inner-with-action {
    border-bottom: 0;
  }

  &.Modal-inner-small {
    width: 30%;
  }

  &.Modal-inner-medium {
    width: 50%;
  }
}

.Modal-header {
  display: flex;
  border-bottom: 1px solid $border-grey;
}

// .Modal-header nesting for higher specificity & override `.Modal-header button`
.Modal-header .Modal-close-button {
  border: 0;
  border-left: 1px solid $border-grey;
  // FIXME: should be 2rem but SVG itself is not properly sized
  padding: 0.666rem;

  &:hover {
    // using an outline here as the `border` is handled by the parent.
    outline: 1px solid $border-grey;
  }

  &:active {
    // using an outline here as the `border` is handled by the parent.
    outline: 1px solid $white;
  }

  &:focus-visible {
    outline: 1px solid $secondary-green;
    outline-offset: -$button-outline-offset;
    background: $black;
  }

  svg {
    // FIXME: should be 1rem but SVG itself is not properly sized
    height: 2rem;
    width: auto;
    // FIXME: should be able to either set `color` on container, or `fill` on svg.
    path {
      fill: $red;
    }
  }
}

.Modal-header h4 {
  margin: 0;
  padding-left: 1.5rem;
  padding-top: 1rem;
  flex-grow: 1;
  font-size: 1.5rem;
  font-weight: normal;
}

.Modal-content {
  padding: 1.5rem;
}

.Modal-footer button {
  width: 100%;
  padding: 1.5rem 0;
  font-size: 1.5rem;
  border: 0;
  border-top: 1px solid $border-grey;
  border-bottom: 1px solid $border-grey;
}
